import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/components/Home.vue'
import Error404 from '@/components/Error404.vue'
import Thanks from '@/components/Thanks.vue'
import SelectPlace from '@/components/SelectPlace.vue'

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'SelectPlace',
    component: SelectPlace,
  },
  {
    path: '/rostov',
    name: 'Rostov',
    component: Home,
  },
  {
    path: '/krasnodar',
    name: 'Krasnodar',
    component: Home,
  },
  {
    path: '/thanks',
    name: 'Thanks',
    component: Thanks,
  },
  {
    path: '*',
    name: '404',
    component: Error404,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
