<template>
  <div class="choose_place">
    <div class="choose_place_wrapper">
      <div class="choose_place_content">
        <h2>Выбери свой город</h2>
        <ul class="choose_place_list">
          <li @click="$router.push({ name: 'Krasnodar' })">
            <div class="icon">
              <img src="@/assets/images/crest_krasnodar.jpg" alt="">
            </div>
            <div class="choose_place_list_item_wrapper">
              <img class="choose_place_list_image" src="@/assets/images/product_0.jpg" alt="">
              <h3>Краснодар</h3>
              <span>Выбрать</span>
            </div>
          </li>
          <li @click="$router.push({ name: 'Rostov' })">
            <div class="icon">
              <img src="@/assets/images/crest_rostov.jpg" alt="">
            </div>
            <div class="choose_place_list_item_wrapper">
              <img class="choose_place_list_image" src="@/assets/images/product_0.jpg" alt="">
              <h3>Ростов-на-Дону</h3>
              <span>Выбрать</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>