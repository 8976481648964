<template>
  <div class="app_wrapper" :class="[`is-${placeName}`]">
    <header class="main_header main_wrapper">
      <router-link :to="{name: 'SelectPlace'}" class="logo">
        Квадро <span></span> Сафари
      </router-link>
      <div class="description">
        Прокат квадроциклов в {{ isRostov ? 'Ростове-на-Дону' : 'Краснодаре'}}
      </div>
      <nav>
        <a href="">Туры</a>
        <a href="">Подарок</a>
        <a href="">Отзывы</a>
        <a href="">О нас</a>
      </nav>
      <div class="phone_wrapper">
        <a :href="`tel:${currentPlace.phone}`" class="phone">{{ currentPlace.phone }}</a>
        <a :href="currentPlace.whatsapp" class="whatsapp"></a>
      </div>
    </header>
    <div class="intro main_wrapper">
      <div class="heading">
        <h1>
          Подари себе<br/>
          и близким<br/>
          <span>незабываемые<br/>
          эмоции</span>
        </h1>
        <div class="heading_pill">
          в {{ isRostov ? 'Ростове-на-Дону' : 'Краснодаре'}}
        </div>
      </div>
      <p class="description">Хотите покататься с близкими или сделать подарок?</p>
      <div class="apply">
        <button class="btn" type="button" @click="showModal">
          <span class="front">Записаться</span>
        </button>
      </div>
    </div>
    <div class="products">
      <div class="product" v-for="(product, productIndex) in currentPlace.products" :key="productIndex">
        <header>
          <div class="header_info">
            <h2>
              <span v-for="item in product.name" :key="item">{{ item }}</span>
            </h2>
            <p>Отлично подойдёт для новичков</p>
          </div>
          <div class="product_time">
            {{ product.duration }}
            <span class="product_time_unit">{{ product.durationUnit}}</span>
            <span class="product_time_label">время</span>
          </div>
        </header>
        <div class="product_image">
          <img :src="require(`@/assets/images/product_${productIndex}.jpg`)" alt="">
        </div>
        <div class="product_info">
          <div class="product_price">
            <span class="amount">{{ product.price }}</span>
            <div class="description">рублей за<br> 1 квадроцикл</div>
            <div class="additional_info_trigger">
              <ul class="additional_info">
                <li>Условия могут измениться в зависимости от погодных условий</li>
                <li>Максимальная грузоподъёмность 180кг</li>
                <li>Время прохождения маршрута зависит от погодных условий и навыков вождения.</li>
              </ul>
            </div>
          </div>
          <div class="text">На 1 квадроцикле может ехать <span>2 человека</span></div>
        </div>
        <button class="btn" type="button" @click="showModal">
          <span class="front">Забронировать</span>
        </button>
      </div>
    </div>
    <div class="gift main_wrapper">
      <h2>
        Возможно вы ищете<br>
        <span>крутой подарок</span>?
      </h2>
      <ul>
        <li v-for="item in giftList" :key="item.title">
          <span>
            <img :src="require(`@/assets/images/${item.icon}.png`)" alt="">
          </span>
          {{ item.title }}
        </li>
      </ul>
      <button class="btn" @click="showModal">
        <span class="front">Забрать подарок</span>
      </button>
    </div>
    <div class="faq">
      <h2>Довольно <span>частые вопросы</span><br> от наших клиентов</h2>
      <ul>
        <li v-for="item in currentPlace.faq" :key="item.question">
          <div class="question" v-html="item.question"></div>
          <div class="answer" v-html="item.answer"></div>
        </li>
      </ul>
    </div>
    <div class="contacts">
      <div class="main_wrapper">
        <h2>
          Посмотрели <span>предложения</span>,<br>
          но что-то не так?
        </h2>
        <div class="contacts_list">
          <section>
            <span class="label">Напишите нам в мессенджеры!</span>
            <ul class="social_media">
              <!-- <li>
                <a :href="currentPlace.vk" target="_blank" class="vk"></a>
              </li> -->
              <li>
                <a :href="currentPlace.instagram" target="_blank" class="instagram"></a>
              </li>
              <li>
                <a :href="currentPlace.whatsapp" target="_blank" class="whatsapp"></a>
              </li>
            </ul>
          </section>
          <section>
            <span class="label">Или позвоните</span>
            <a :href="`tel:${currentPlace.phone}`" class="phone">{{ currentPlace.phone }}</a>
          </section>
          <section>
            <span class="label">Наши адреса</span>
            <p class="address">{{ currentPlace.address }}</p>
          </section>
          <section>
            <button class="btn" @click="showModal">
              <span class="front">Быстрый звонок</span>
            </button>
          </section>
        </div>
      </div>
    </div>
    <div class="choose_us main_wrapper">
      <ul class="list">
        <li v-for="(item, itemIndex) in currentPlace.chooseUs" :key="itemIndex">
          <span class="amount">{{ item.amount }}</span>
          <span class="title" v-html="item.title"></span>
        </li>
      </ul>
      <div class="info" v-if="isRostov">
        <h3>
          Для тех, кто уже<br>
          все пробовал,<br>
          возможность<br>
          <span>прогулки Off-road</span>.
        </h3>
        <ul>
          <span class="separator"></span>
          <li v-for="item in chooseUsInfo" :key="item" v-html="item"></li>
          <span class="separator"></span>
        </ul>
      </div>
    </div>
    <div class="reviews">
      <h2>Здесь стоит показывать <span>отзывы</span></h2>
      <p class="description">и мы делаем это спокойно, так как они настоящие.</p>
      <ul>
        <li v-for="item in 3" :key="item">
          <a href="https://yandex.ru/MapS/org/kvadro_safari/1428486866/reviews/?ll=39.524220%2C47.160175&z=18" target="_blank">
            <img :src="require(`@/assets/images/review_${item}.png`)">
            <div>
              <span class="button"></span>
              <p class="raiting">
                4.7
                <span>рейтинг</span>
              </p>
            </div>
          </a>
        </li>
      </ul>
    </div>
    <footer class="footer main_wrapper">
      <h2>Наши <span>контакты</span></h2>
      <p class="description">А кроме, лучше всего - это просто глянуть  на счастливые лица наших клиентов.</p>
      <div class="contacts_list">
        <a :href="currentPlace.instagram" target="_blank" class="contacts_list_smarphone"></a>
        <div class="column">
          <section>
            <span class="label">Напишите нам в мессенджеры!</span>
            <ul class="social_media">
              <!-- <li>
                <a :href="currentPlace.vk" target="_blank" class="vk"></a>
              </li> -->
              <li>
                <a :href="currentPlace.instagram" target="_blank" class="instagram"></a>
              </li>
              <li>
                <a :href="currentPlace.whatsapp" target="_blank" class="whatsapp"></a>
              </li>
            </ul>
          </section>
          <section>
            <span class="label">Наши адреса</span>
            <p class="address">{{ currentPlace.address }}</p>
          </section>
        </div>
        <div class="column">
          <section>
            <span class="label">Или позвоните</span>
            <a :href="`tel:${currentPlace.phone}`" class="phone">{{ currentPlace.phone }}</a>
          </section>
          <section>
            <button class="btn" @click="showModal">
              <span class="front">Быстрый звонок</span>
            </button>
          </section>
        </div>
      </div>
    </footer>
    <div class="order_form" :class="{'is-opened': isFormModalOpened}">
      <div class="order_form_wrapper">
        <div class="order_form_overlay" @click="isFormModalOpened = false"></div>
        <form @submit.prevent="submitForm">
          <button type="button" class="order_form_close" @click="isFormModalOpened = false"></button>
          <h2>Оставить <span>заявку</span></h2>
          <!-- <div class="order_form_block success" v-if="sendFormStatus === 'success'">
            Спасибо за вашу заявку!<br>
            Наш менеджер скоро вам перезвонит.
          </div> -->
          <div class="order_form_block error" v-if="sendFormStatus === 'error'">
            Что-то пошло не так.<br>
            Пожалуйста, попробуйте снова.
          </div>
          <div class="order_form_block" v-else>
            <div class="order_form_container">
              <input type="text" placeholder="Ваш секрет" class="secret" v-model="secret">
              <input required type="text" placeholder="Ваше имя" v-model="name">
              <!-- <input required type="email" placeholder="Ваш email" v-model="email"> -->
              <input required type="tel" placeholder="Ваш телефон" v-model="phone">
              <button class="btn"><span class="front">Забронировать</span></button>
            </div>
            <p class="policy">при клике вы соглашаетесь с политикой конфиденциальности и обработкой персональных данных</p>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      info: {
        rostov: {
          vk: 'https://vk.com/kvadrosafari',
          instagram: 'https://www.instagram.com/kvadrosafari_rostov/',
          whatsapp: 'https://wa.me/+79897183767',
          phone: '+7 (989) 718-37-67',
          address: 'Городище, Геологическая, 52',
          products: [
            {
              name: ['Легкая', 'прогулка'],
              duration: '30-40',
              durationUnit: 'м',
              price: 2500,
            },
            {
              name: ['Экстрим', 'прогулка'],
              duration: '60-90',
              durationUnit: 'м',
              price: 4000,
            },
          ],
          faq: [
            {
              question: 'Какой к вам<br>транспорт ходит?',
              answer: 'От ГПЗ-10 маршрутки хотят каждые 20-30 минут в х. Обуховку, х. Дугино и х.Рогожкино',
            },
            {
              question: 'За рулем квадрика сам едешь?<br>Или тебя везут? Права нужны?',
              answer: 'За рулем едете сами, Нужен документ для составления договора-аренды. Прогулка проходит в сопровождении инструктора',
            },
            {
              question: 'А если прав нет?',
              answer: 'Если есть первоначальные навыки вождения, то скорее всего, Вы справитесь',
            },
            {
              question: 'До какого числа будут<br>поездки?',
              answer: 'Мы работаем круглый год. В каждом времени года свои плюсы!',
            },
            {
              question: 'С инструктором разрешено,<br>а без него можно?',
              answer: 'Все наши поездки проходят в сопровождении опытного инструктора, который покажет Вам маршрут и поможет в случаях затруднительного прохождения',
            },
            {
              question: 'А если приехать зимой - <br>прикольно?',
              answer: 'Квадроцикл - техника всесезонная, на нем приколько и зимой и летом!',
            },
          ],
          chooseUs: [
            {
              amount: '5',
              title: 'квадроциклов и<br><mark>1 багги</mark> в автопарке',
            },
            {
              amount: '10',
              title: 'минутах <br>от города',
            },
            {
              amount: '8',
              title: 'лет<br>опыта',
            },
            {
              amount: '10К',
              title: 'довольных клиентов в год ',
            },
          ],
        },
        krasnodar: {
          vk: 'https://vk.com/kvadrosafari',
          instagram: 'https://www.instagram.com/kvadrosafari_krd/',
          whatsapp: 'https://wa.me/+79183817676',
          phone: '+7 (918) 381-76-76',
          address: 'Россия, Краснодарский край, городской округ Горячий Ключ, хутор Молькино',
          products: [
            {
              name: ['Легкая', 'прогулка'],
              duration: '30-40',
              durationUnit: 'м',
              price: 2000,
            },
            {
              name: ['Экстрим', 'прогулка'],
              duration: '60-90',
              durationUnit: 'м',
              price: 3500,
            },
          ],
          faq: [
            {
              question: 'Какой к вам<br>транспорт ходит?',
              answer: 'Городской транспорт (маршрутка) до Хутора Молькино, 5 минут пешком, либо на электричке до станции Молькино.',
            },
            {
              question: 'За рулем квадрика сам едешь?<br>Или тебя везут? Права нужны?',
              answer: 'За рулем едете сами, Нужен документ для составления договора-аренды. Прогулка проходит в сопровождении инструктора',
            },
            {
              question: 'А если прав нет?',
              answer: 'Если есть первоначальные навыки вождения, то скорее всего, Вы справитесь',
            },
            {
              question: 'До какого числа будут<br>поездки?',
              answer: 'Мы работаем круглый год. В каждом времени года свои плюсы!',
            },
            {
              question: 'С инструктором разрешено,<br>а без него можно?',
              answer: 'Все наши поездки проходят в сопровождении опытного инструктора, который покажет Вам маршрут и поможет в случаях затруднительного прохождения',
            },
            {
              question: 'А если приехать зимой - <br>прикольно?',
              answer: 'Квадроцикл - техника всесезонная, на нем приколько и зимой и летом!',
            },
          ],
          chooseUs: [
            {
              amount: '5',
              title: 'квадроциклов в автопарке',
            },
            {
              amount: '20',
              title: 'минутах <br>от города',
            },
            {
              amount: '8',
              title: 'лет<br>опыта',
            },
            {
              amount: '10К',
              title: 'довольных клиентов в год ',
            },
          ],
        },
      },
      giftList: [
        {
          title: 'Лучший подарок для ваших близких',
          icon: 'giftbox',
        },
        {
          title: 'Подарочные сертификаты на все виды маршрутов',
          icon: 'certificate',
        },
        {
          title: 'На одном квадроцикле может ехать 2 человека',
          icon: 'man-and-woman-couple-with-a-table',
        },
        {
          title: 'Подарочный сертификат можно оформить удалённо',
          icon: 'certificate',
        },
      ],
      chooseUsInfo: [
        'Индивидуально разработанный маршрут',
        'Минимум 5 ед. техники',
        'Любое кол-во<br>времени',
      ],
      isFormModalOpened: false,
      secret: '',
      name: '',
      email: '',
      phone: '',
      sendFormStatus: '',
    };
  },
  computed: {
    placeName() {
      return this.$route.name.toLowerCase();
    },
    currentPlace() {
      return this.info[this.placeName];
    },
    isRostov() {
      return this.placeName === 'rostov';
    },
  },
  methods: {
    async submitForm() {
      try {
        const { data } = await axios.post('./send_form.php', {
          name: this.name,
          // email: this.email,
          phone: this.phone,
          secret: this.secret,
          place: this.$route.name.toLowerCase(),
        });
        if(data === 'Успешно!' || data === 'Спасибо!') {
          this.$router.push({ name: 'Thanks' });
          this.sendFormStatus = 'success';
        } else {
          this.sendFormStatus = 'error';
        }
      } catch (error) {
        this.sendFormStatus = 'error';
      }
    },
    showModal() {
      this.isFormModalOpened = true;
      this.sendFormStatus = '';
      this.name = '';
      this.email = '';
      this.phone = '';
      this.secret = '';
    },
  },
}
</script>

<style>

</style>