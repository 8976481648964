<template>
  <div class="app_wrapper thanks">
    <h1>
      Спасибо за вашу заявку!
      <span>Наш менеджер скоро вам перезвонит.</span>
      <router-link :to="prevRoute.path" v-if="prevRoute.name">Вернуться назад</router-link>
    </h1>
    <noscript><img height="1" width="1" style="display:none"
    src="https://www.facebook.com/tr?id=203070498443950&ev=PageView&noscript=1"
    /></noscript>
  </div>
</template>

<script>
!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '203070498443950');
fbq('track', 'PageView');

export default {
  data() {
    return {
      prevRoute: '',
    };
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from;
    })
  },
}
</script>