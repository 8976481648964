<template>
  <div id="app">
    <router-view :key="$route.name" />
  </div>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style lang="scss">
@import url(./assets/styles/reset.css);
@import url(./assets/styles/fonts.css);

:root {
  --font-1: 'Bebas Neue', sans-serif;
  --font-2: 'Montserrat', sans-serif;
  --color-1: #b18564;
  --color-2: #9a7559;
  --color-3: #ffcca5;
  --color-5: #fbe2cf;
  --color-6: #fff0e5;
  --color-7: #ca9c79;
}

.order_form {
  background-color: hsla(40, 20%, 94%, 0.7);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  backdrop-filter: blur(6px);
  display: flex;
  overflow: auto;
  padding: 20px;
  box-sizing: border-box;
  opacity: 0;
  visibility: hidden;
  transition: .125s ease;

  &.is-opened {
    opacity: 1;
    visibility: visible;

    .order_form_wrapper {
      transform: none;
    }
  }
  .order_form_container {
    max-width: 300px;
    margin: 30px auto 0;
  }
  .order_form_overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
  .order_form_wrapper {
    max-width: 500px;
    width: 100%;
    margin: auto;
    transform: translateY(50px) scale(.5);
    transition: .275s ease;
  }
  form {
    background-color: #fff;
    border-radius: 15px;
    padding: 70px 40px 40px;
    position: relative;

    input {
      height: 80px;
      border-radius: 40px;
      border: none;
      background-color: #eceae6;
      padding: 0 30px;
      font-family: var(--font-2);
      font-weight: 600;
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: 0.08em;
      display: block;
      width: 100%;
      box-sizing: border-box;
      margin-bottom: 10px;

      &:focus {
        outline: none;
      }
      @media screen and (max-width: 700px) {
        height: 60px;
      }
    }
    .btn {
      width: 100%;
      margin-top: 40px;
    }
    .secret {
      z-index: -1;
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
    }
  }
  h2 {
    font-size: 70px;
    line-height: 0.9em;
    font-weight: 700;
    width: fit-content;
    mask: url(./assets/images/header_mask_6.png) top left / 100% 100% no-repeat;
    margin: 0 auto;
    text-align: center;

    span {
      color: #8e8e8e;
    }
  }
  .order_form_close {
    appearance: none;
    background: url(./assets/images/close.svg) center / 20px 20px no-repeat;
    height: 30px;
    width: 30px;
    padding: 0;
    border: none;
    top: 20px;
    right: 20px;
    position: absolute;
    cursor: pointer;

    &:focus {
      outline: none;
    }
  }
  .policy {
    font-family: var(--font-2);
    font-size: 14px;
    opacity: 0.45;
    line-height: 1.5em;
    font-weight: 500;
    text-align: center;
    max-width: 450px;
    width: 100%;
    margin: 25px auto 0;
  }
  .success {
    text-align: center;
    font-family: var(--font-2);
    font-size: 16px;
    line-height: 1.5;
    margin: 50px 0 30px;
  }
  .error {
    text-align: center;
    font-family: var(--font-2);
    font-size: 16px;
    line-height: 1.5;
    margin: 50px 0 30px;
    color: rgb(224, 31, 31);
  }
}

.choose_place {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 20px;
  box-sizing: border-box;
  transition: .125s ease;

  .choose_place_wrapper {
    flex: 1;
    width: 100%;
    position: relative;
    display: flex;

    &::before {
      top: -20px;
      bottom: -20px;
      left: -20px;
      right: -20px;
      content: '';
      position: absolute;
      background: url(./assets/images/1.jpg) center / cover no-repeat;
      filter: blur(6px);
    }
    &::after {
      background-color: hsla(40, 20%, 94%, 0.7);
      top: -20px;
      bottom: -20px;
      left: -20px;
      right: -20px;
      content: '';
      position: absolute;
    }
  }
  .choose_place_content {
    background-color: #f2f0eb;
    border-radius: 36px;
    padding: 50px;
    box-shadow: 0 10px 50px hsla(26, 38%, 18%, 0.2);
    width: 100%;
    max-width: 800px;
    margin: auto;
    box-sizing: border-box;
    position: relative;
    z-index: 1;
  }
  h2 {
    font-size: 40px;
    padding-top: .1em;
    font-weight: 700;
    width: fit-content;
    mask: url(./assets/images/header_mask_6.png) top left / 100% 100% no-repeat;
    margin: 0 auto;
    text-align: center;
    color: var(--color-1);
  }
  li {
    position: relative;

    &:nth-child(2) .choose_place_list_image {
      filter: grayscale(1);
    }
  }
  .choose_place_list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    gap: 30px;
    margin-top: 50px;

    .choose_place_list_item_wrapper {
      background-color: #fff;
      border-radius: 27px;
      padding: 0 15px 50px;
      overflow: hidden;
      cursor: pointer;
      position: relative;

      h3 {
        font-size: 40px;
        font-weight: 700;
        color: #2a2a2a;
        text-align: center;
        position: relative;
      }
      span {
        font-size: 14px;
        color: var(--color-1);
        letter-spacing: 0.25em;
        font-weight: 600;
        display: block;
        width: fit-content;
        margin: 10px auto 0;
        border-bottom: 1px solid currentColor;
        position: relative;
      }
      .choose_place_list_image {
        width: 120%;
        margin: -15% 0 -30% -10%;
      }
    }
    .icon {
      position: absolute;
      z-index: 1;
      padding: 15px 20px;
      background-color: #fff;
      border-radius: 27px;
      box-shadow: 0 3px 50px rgba(0, 0, 0, .1);
      left: 30px;
      top: -15px;

      img {
        width: 60px;
      }
    }
    @media screen and (max-width: 700px) {
      grid-template-columns: 1fr;
      max-width: 350px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

html,
body {
  height: 100%;
}

#app {
  font-family: var(--font-1);
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  background-color: #f3f1ed;
}
.app_wrapper {
  background: url(./assets/images/1.jpg) top center / 100% auto no-repeat;
}

.main_wrapper {
  margin: 0 auto;
  max-width: 1110px;
  padding-left: 20px;
  padding-right: 20px;
}

.btn {
  display: block;
  cursor: pointer;
  appearance: none;
  border: none;
  background-color: var(--color-2);
  border-radius: 80px;
  height: 80px;
  padding: 0;
  box-shadow: 0 4px 90px rgba(154, 117, 89, .2);
  width: fit-content;
  position: relative;
  min-width: 250px;

  .front {
    border-radius: inherit;
    background: linear-gradient(to bottom, var(--color-3), var(--color-1));
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    padding: 0 50px;
    transform: translateY(-4px);
    box-sizing: border-box;
    color: var(--color-5);
    font-family: var(--font-2);
    font-weight: 600;
    font-size: 16px;
    letter-spacing: .08em;
    text-transform: uppercase;
  }

  &:focus {
    outline: none;
  }
  &:active {
    .front {
      transform: translateY(-2px);
    }
  }
  @media screen and (max-width: 700px) {
    height: 60px;

    .front {
      font-size: 14px;
      letter-spacing: 0.04em;
      padding: 0 30px;
    }
  }
}

.main_header {
  align-items: center;
  display: flex;
  padding-top: 45px;
  padding-bottom: 45px;
  flex-wrap: wrap;

  .logo {
    align-items: center;
    color: var(--color-1);
    display: flex;
    font-size: 25px;
    font-weight: 700;
    text-decoration: none;
    
    span {
      background: url(./assets/images/motorbike.png) center / 1em 1em no-repeat;
      background-color: currentColor;
      border-radius: 50%;
      height: 1.2em;
      width: 1.2em;
      margin: 0 .075em;
    }
  }

  .description {
    font-family: var(--font-2);
    font-size: 12px;
    font-weight: 500;
    opacity: 0.55;
    width: 145px;
    margin-left: 20px;

    @media screen and (max-width: 960px) {
      display: none;
    }
  }

  nav {
    display: flex;
    font-family: var(--font-2);
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.08em;
    margin: 0 auto;

    @media screen and (max-width: 750px) {
      display: none;
    }
    a {
      text-decoration: none;
      text-transform: uppercase;
      color: #000;

      &:not(:last-child) {
        margin-right: 40px;
      }
    }
  }

  .phone_wrapper {
    display: flex;

    @media screen and (max-width: 750px) {
      margin-left: auto;
    }
  }
  .phone {
    display: flex;
    align-items: center;
    font-size: 25px;
    color: #000;
    text-decoration: none;
    font-weight: 700;
  }
  .whatsapp {
    display: block;
    width: 24px;
    height: 24px;
    background: url(./assets/images/whatsapp.png) center / contain no-repeat;
    margin-left: 15px;
  }

   @media screen and (max-width: 450px) {
    flex-direction: column;
    align-items: flex-start;

    .logo {
      font-size: 20px;
    }
    .phone_wrapper {
      margin-left: 0;
      margin-top: 20px;
    }
    .phone {
      font-size: 20px;
    }
  }
}

.page_404 {
  height: 100vh;
  background-size: cover;
  display: flex;
  padding: 30px;
  box-sizing: border-box;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #fff;
    opacity: 0.65;
  }
  h1 {
    color: #000;
    font-size: 150px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 0.9em;
    mask: url(./assets/images/header_mask.png) center / 100% 100% no-repeat;
    margin: auto;
    text-align: center;

    span {
      color: var(--color-1);
      display: block;
      font-size: .5em;
      margin-top: -0.3em;
    }

    @media screen and (max-width: 700px) {
      font-size: 55px;
    }
  }
}

.thanks {
  height: 100vh;
  background-size: cover;
  display: flex;
  padding: 30px;
  box-sizing: border-box;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #fff;
    opacity: 0.65;
  }
  h1 {
    color: #000;
    font-size: 100px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 0.9em;
    mask: url(./assets/images/header_mask.png) center / 100% 100% no-repeat;
    margin: auto;
    text-align: center;

    span {
      color: var(--color-1);
      display: block;
      font-size: .5em;
      margin-top: 0.25em;
      line-height: 0.9em;
    }

    a {
      margin-top: 100px;
      display: block;
      font-size: 0.25em;
      color: inherit;
      text-decoration-skip-ink: none;
    }

    @media screen and (max-width: 700px) {
      font-size: 55px;

      a {
        font-size: 0.35em;
      }
    }
    @media screen and (max-width: 350px) {
      font-size: 40px;

      a {
        font-size: 0.4em;
      }
    }
  }
}

.intro {
  margin-top: 100px;

  @media screen and (max-width: 500px) {
    margin-top: 25vw;
  }
  h1 {
    color: #000;
    font-size: 85px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 0.9em;
    mask: url(./assets/images/header_mask.png) center / 100% 100% no-repeat;

    span {
      color: var(--color-1);
    }

    @media screen and (max-width: 700px) {
      font-size: 55px;
    }
    @media screen and (max-width: 500px) {
      font-size: 40px;
    }
  }
  .heading {
    position: relative;
    width: fit-content;
  }
  .heading_pill {
    color: #fff;
    font-size: 40px;
    font-weight: 700;
    text-transform: uppercase;
    height: 50px;
    background-color: var(--color-1);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: .1em 15px 0;
    box-sizing: border-box;
    position: absolute;
    bottom: 18px;
    left: 235px;
    white-space: nowrap;

    @media screen and (max-width: 700px) {
      font-size: 30px;
      bottom: 0;
      left: 155px;
    }
    @media screen and (max-width: 500px) {
      font-size: 20px;
      bottom: 3px;
      left: 110px;
      height: 35px;
      padding-left: 10px;
      padding-right: 10px;
      border-radius: 7px;
    }
  }
  .description {
    font-family: var(--font-2);
    font-size: 18px;
    line-height: 1.5em;
    font-weight: 500;
    width: 300px;
    margin-top: 20px;
  }

  .apply {
    position: relative;
    width: fit-content;
    margin-top: 90px;

    &::after {
      content: '';
      position: absolute;
      width: 150px;
      height: 70px;
      background: url(./assets/images/8.png) center / contain no-repeat;
      bottom: 60%;
      left: 100%;
      margin-left: 10px;
    }

    @media screen and (max-width: 450px) {
      margin-top: 150px;

      &::after {
        bottom: 140%;
        margin-left: -120px;
      }
    }
  }
}

.products {
  display: flex;
  justify-content: center;
  max-width: 960px;
  margin: 350px auto 300px;
  position: relative;
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
  z-index: 1;

  &::before {
    content: '';
    position: absolute;
    width: 100vw;
    height: calc(100vw * 0.8966);
    background: url(./assets/images/2.png) center / contain no-repeat;
    left: 50%;
    margin-left: -50vw;
    top: -6vw;
  }

  &::after {
    content: '';
    position: absolute;
    width: 120px;
    height: 80px;
    background: url(./assets/images/9.png) center / contain no-repeat;
    left: 100%;
    margin-left: 20px;
    bottom: 30%;

    .is-krasnodar & {
      display: none;
    }
  }
  .product {
    background-color: #fff;
    border-radius: 15px;
    padding: 50px 40px 0;
    box-sizing: border-box;
    position: relative;

    &:not(:last-child) {
      margin-right: 50px;
    }
    header {
      align-items: flex-start;
      display: flex;
      position: relative;
      z-index: 1;

      h2 {
        font-size: 60px;
        font-weight: 700;
        line-height: 0.9em;
        margin-top: 15px;
        mask: url(./assets/images/header_mask_1.png) top left / 100% 100% no-repeat;

        span {
          display: block;

          &:nth-child(2) {
            color: #8e8e8e;
          }
        }
      }
    }
    .header_info {
      p {
        font-size: 14px;
        font-family: var(--font-2);
        color: #000;
        opacity: .45;
        font-weight: 500;
        text-transform: lowercase;
        width: 150px;
        margin-top: 5px;
      }
    }
    @media screen and (max-width: 500px) {
      padding: 30px 20px 0;
    }
  }
  .product_image {
    align-items: center;
    display: flex;
    height: 370px;
    margin: 0 -40px;
    position: relative;

    img {
      width: 100%;
      position: absolute;
    }
    @media screen and (max-width: 500px) {
      margin: 0 -20px;
      height: 300px;
    }
  }
  .product_time {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background-color: var(--color-6);
    border-radius: 15px;
    width: 110px;
    font-size: 40px;
    color: var(--color-1);
    font-weight: 700;
    padding-bottom: 20px;
    margin-left: auto;

    .product_time_unit {
      font-size: 25px;
      align-self: flex-end;
      margin-bottom: 0.15em;
    }
    .product_time_label {
      width: 100%;
      text-align: center;
      font-size: 14px;
      font-family: var(--font-2);
      color: #000;
      opacity: .45;
      font-weight: 500;
      margin-top: 5px;
    }
    &::before {
      content: '';
      width: 100%;
      height: 100px;
      background: url(./assets/images/12.png) center / contain no-repeat;
      margin-top: -36px;
      margin-bottom: 10px;
    }
    @media screen and (max-width: 500px) {
      font-size: 30px;
      width: 90px;

      .product_time_unit {
        font-size: 18px;
      }
      .product_time_label {
        font-size: 12px;
        margin-top: 0;
      }
      &::before {
        height: 70px;
        margin-bottom: 0;
        margin-top: -15px;
      }
    }
  }

  .product_info {
    position: relative;

    .text {
      font-family: var(--font-2);
      opacity: 0.45;
      font-weight: 500;
      font-size: 14px;
      line-height: 1.45em;
      text-transform: lowercase;
      width: 180px;
      margin-top: 5px;

      span {
        text-decoration: underline;
      }
    }
  }
  .product_price {
    display: flex;
    align-items: center;

    .amount {
      font-size: 80px;
      font-weight: 700;
      color: var(--color-1);
    }
    .description {
      font-size: 30px;
      font-weight: 700;
      line-height: 0.9em;
      margin-left: 10px;
      color: #464646;
    }

    @media screen and (max-width: 500px) {
      .amount {
        font-size: 60px;
      }
      .description {
        font-size: 25px;
      }
    }
  }

  .additional_info {
    position: absolute;
    visibility: hidden;
    right: 0;
    top: 100%;
    background-color: #fff;
    width: 250px;
    padding: 25px;
    box-sizing: border-box;
    box-shadow: 0 45px 50px 8px rgba(110, 110, 110, .1);
    border-radius: 15px;

    li {
      position: relative;
      padding-left: 25px;
      font-size: 12px;
      font-family: var(--font-2);
      color: rgba(0, 0, 0, .45);
      line-height: 1.6em;
      font-weight: 500;

      &:not(:last-child) {
        margin-bottom: 1.6em;
      }
      &::before {
        position: absolute;
        content: '';
        width: 5px;
        height: 5px;
        background-color: var(--color-7);
        border-radius: 50%;
        left: 0;
        top: 8px;
      }
    }
  }
  .additional_info_trigger {
    z-index: 2;
    width: 35px;
    height: 35px;
    flex-shrink: 0;
    margin-left: auto;
    cursor: pointer;
    position: relative;

    &::before {
      background: url(./assets/images/question.png) center / contain no-repeat;
      opacity: 0.4;
      content: '';
      display: block;
      width: 100%;
      height: 100%;
    }
    &:hover .additional_info {
      visibility: visible;
    }
    @media screen and (max-width: 500px) {
      width: 25px;
      height: 25px;
    }
  }

  .btn {
    margin: 50px auto 0;
    transform: translateY(50%);

    @media screen and (max-width: 500px) {
      margin-top: 20px;
    }
  }

  @media screen and (max-width: 960px) {
    flex-direction: column;
    max-width: 450px;
    margin-top: 100px;
    margin-bottom: 200px;

    .product:not(:last-child) {
      margin-right: 0;
      margin-bottom: 100px;
    }
  }
  @media screen and (max-width: 500px) {
    max-width: 400px;
    margin-bottom: 150px;

    .product:not(:last-child) {
      margin-right: 0;
      margin-bottom: 50px;
    }
  }
}

.gift {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    width: 1382px;
    height: 1173px;
    background: url(./assets/images/6.jpg) center / contain no-repeat;
    top: -150px;
    left: 220px;

    @media screen and (max-width: 700px) {
      left: 150px;
      width: 900px;
      height: 900px;
      top: 0;
    }
  }
  h2 {
    font-size: 70px;
    line-height: 0.9em;
    font-weight: 700;
    width: fit-content;
    mask: url(./assets/images/header_mask_2.png) top left / 100% 100% no-repeat;
    margin-bottom: 80px;

    span {
      color: var(--color-1);
    }
    @media screen and (max-width: 500px) {
      margin-bottom: 50px;
    }
  }
  ul {
    margin-bottom: 120px;
    position: relative;

    li {
      align-items: center;
      display: flex;
      font-size: 18px;
      font-family: var(--font-2);
      font-weight: 500;
      line-height: 1.5em;
      max-width: 370px;

      &:not(:last-child) {
        margin-bottom: 40px;
      }
      span {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80px;
        height: 80px;
        background-color: #fff;
        border-radius: 15px;
        margin-right: 30px;
        flex-shrink: 0;

        img {
          height: 50%;
          width: 50%;
        }
      }

      @media screen and (max-width: 700px) {
        font-size: 16px;
        max-width: 300px;
      }
    }
  }
}

.faq {
  position: relative;
  max-width: 920px;
  margin: 330px auto 0;
  padding-left: 20px;
  padding-right: 20px;


  @media screen and (max-width: 700px) {
    margin-top: 180px;
  }
  &::before {
    position: absolute;
    content: '';
    width: 100vw;
    height: calc(100vw * 0.2913);
    background: url(./assets/images/3.png) center / 100% 100% no-repeat;
    left: 50%;
    margin-left: -50vw;
    top: -210px;

    @media screen and (max-width: 700px) {
      top: -50px;
    }
  }
  h2 {
    font-size: 70px;
    line-height: 0.9em;
    font-weight: 700;
    width: fit-content;
    mask: url(./assets/images/header_mask_3.png) top left / 100% 100% no-repeat;
    margin: 0 auto 120px;
    text-align: center;
    position: relative;

    span {
      color: var(--color-1);
    }

    @media screen and (max-width: 700px) {
      margin-bottom: 50px;
    }
  }
  ul {
    position: relative;

    li {
      align-items: center;
      display: flex;
      box-sizing: border-box;
      padding: 35px 25px;
      background-color: #fff;
      border-radius: 15px;

      &:not(:last-child) {
        margin-bottom: 20px;
      }

      @media screen and (max-width: 700px) {
        padding: 20px;

        &:not(:last-child) {
          margin-bottom: 10px;
        }
      }
      @media screen and (max-width: 500px) {
        flex-direction: column;
      }
    }
  }
  .question {
    align-items: center;
    display: flex;
    font-size: 25px;
    line-height: 1em;
    width: 50%;
    flex-shrink: 0;
    color: var(--color-1);
    font-weight: 700;

    &::before {
      content: '';
      width: 55px;
      height: 55px;
      border-radius: 15px;
      margin-right: 30px;
      background: url(./assets/images/question.png) center / 24px 24px no-repeat, var(--color-6);
      flex-shrink: 0;
    }

    @media screen and (max-width: 700px) {
      font-size: 20px;

      &::before {
        width: 40px;
        height: 40px;
        background-size: 16px 16px;
        border-radius: 10px;
        margin-right: 15px;
      }
    }
    @media screen and (max-width: 500px) {
      width: 100%;
    }
  }
  .answer {
    font-family: var(--font-2);
    font-weight: 500;
    line-height: 1.5em;
    font-size: 16px;
    display: flex;
    align-items: center;

    &::before {
      content: '';
      width: 20px;
      height: 20px;
      background: url(./assets/images/right-arrow.png) center / contain no-repeat;
      opacity: 0.25;
      flex-shrink: 0;
      margin-right: 30px;
    }
    @media screen and (max-width: 700px) {
      font-size: 14px;
    }
    @media screen and (max-width: 500px) {
      width: 100%;
      margin-top: 15px;

      &::before {
        display: none;
      }
    }
  }
}

.contacts {
  --height-top-picture: calc(100vw * 1.0852);
  --height-bottom-picture: calc(100vw * 0.2306);
  background-color: #2c2a27;
  position: relative;
  margin-top: calc(var(--height-top-picture) * 0.135);
  margin-bottom: calc(var(--height-bottom-picture) * 0.65);
  padding-top: 170px;

  @media screen and (max-width: 700px) {
    margin-top: calc(var(--height-top-picture) * 0.25);
  }
  @media screen and (max-width: 500px) {
    padding-top: 100px;
  }
  &::after {
    content: '';
    position: absolute;
    width: 100vw;
    height: var(--height-bottom-picture);
    background: url(./assets/images/5.jpg) center / 100% 100% no-repeat;
    bottom: calc( -1 * (var(--height-bottom-picture) * 0.9) );
  }
  &::before {
    content: '';
    position: absolute;
    width: 100vw;
    height: var(--height-top-picture);
    background: url(./assets/images/4.png) center / 100% 100% no-repeat;
    top: calc( -1 * (var(--height-top-picture) * 0.482) );
  }
  .main_wrapper {
    max-width: 960px;
    position: relative;
    z-index: 1;
  }
  h2 {
    color: #fff;
    font-size: 70px;
    line-height: 0.9em;
    font-weight: 700;
    width: fit-content;
    mask: url(./assets/images/header_mask_3.png) top left / 100% 100% no-repeat;
    margin: 0 auto;
    text-align: center;
    position: relative;

    span {
      color: var(--color-1);
    }
  }
  .contacts_list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 90px 50px;
    position: relative;
    padding-bottom: 150px;
    margin-top: 100px;

    @media screen and (max-width: 700px) {
      grid-template-columns: 1fr;
      gap: 50px;
      width: fit-content;
      margin: 50px auto 0;
    }
    @media screen and (max-width: 500px) {
      padding-bottom: 100px;

      section {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
      }
    }
  }
  .label {
    font-size: 18px;
    font-weight: 500;
    font-family: var(--font-2);
    color: #fff;
    opacity: 0.45;
    line-height: 1.5em;

    @media screen and (max-width: 700px) {
      font-size: 14px;
    }
  }
  .social_media {
    display: flex;
    margin-top: 25px;

    a {
      display: block;
      width: 55px;
      height: 55px;
      background: linear-gradient(to bottom, var(--color-3), var(--color-1));
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      &::before {
        content: '';
        background-size: contain;
        background-position: center center;
        width: 28px;
        height: 28px;

      }
      &.vk::before {
        background-image: url(./assets/images/vk.png);
      }
      &.instagram::before {
        background-image: url(./assets/images/instagram.png);
      }
      &.whatsapp::before {
        background-image: url(./assets/images/whatsapp_brown.png);
      }
    }
    li:not(:last-child) {
      margin-right: 15px;
    }
    @media screen and (max-width: 700px) {
      margin-top: 10px;

      a {
        width: 40px;
        height: 40px;

        &::before {
          height: 16px;
          width: 16px;
        }
      }
      li:not(:last-child) {
        margin-right: 5px;
      }
    }
  }
  .phone {
    font-size: 40px;
    font-family: var(--font-2);
    font-weight: 500;
    line-height: 55px;
    margin-top: 25px;
    display: block;
    color: #fff;
    text-decoration: none;

    @media screen and (max-width: 700px) {
      font-size: 16px;
      margin-top: 10px;
      line-height: 1em;
    }
  }
  .address {
    color: #fff;
    font-family: var(--font-2);
    font-size: 25px;
    line-height: 1.2em;
    width: 250px;
    margin-top: 10px;

    @media screen and (max-width: 700px) {
      font-size: 16px;
      width: 200px;
    }
  }
  .btn {
    margin-top: 15px;
    box-shadow: 0 4px 90px hsla(26, 27%, 48%, .4);

    .front {
      color: #2c2a27;
    }
  }
}

.choose_us {
  position: relative;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 500px) {
    flex-direction: column;
    align-items: center;
  }
  &::before {
    content: '';
    position: absolute;
    width: 977px;
    height: 900px;
    background: url(./assets/images/7.png) center / contain no-repeat;
    left: 325px;
    top: -115px;
    z-index: 2;
  }
  &::after {
    content: '';
    position: absolute;
    width: 1590px;
    height: 672px;
    background: url(./assets/images/18.jpg) center / contain no-repeat;
    left: -159px;
    top: 131px;
  }
  .list {
    width: 50%;
    padding-top: 50px;
    
    @media screen and (max-width: 500px) {
      width: 100%;
    }
    li {
      display: flex;
      align-items: center;
      padding: 20px 0;
      position: relative;
      z-index: 1;

      &:not(:last-child) {
        border-bottom: 1px solid hsla(36, 6%, 16%, 0.15);
      }
    }
    .amount {
      font-size: 80px;
      color: var(--color-1);
      font-weight: 700;
      line-height: 1em;
      margin-right: 25px;
      width: 120px;
      text-align: right;
      flex-shrink: 0;

      @media screen and (max-width: 700px) {
        font-size: 50px;
        width: 60px;
      }
    }
    .title {
      font-family: var(--font-2);
      font-size: 18px;
      line-height: 1.5em;
      color: #292723;
      max-width: 190px;

      @media screen and (max-width: 700px) {
        font-size: 16px;
        max-width: 150px;
      }
    }
  }
  .info {
    background-color: #fff;
    box-sizing: border-box;
    border-radius: 0 0 15px 15px;
    position: relative;
    top: 35px;
    width: 260px;
    padding: 50px 30px;
    position: relative;
    filter: drop-shadow(0 45px 50px hsla(210, 1%, 43%, 0.1));
    z-index: 2;
    
    @media screen and (max-width: 500px) {
      margin-top: 50px;
    }
    &::before {
      content: '';
      display: block;
      height: 35px;
      background-color: inherit;
      mask: url(./assets/images/info_mask.png) center top / 100% 100% no-repeat;
      top: -34px;
      border-radius: 15px 15px 0 0;
      position: absolute;
      width: 100%;
      left: 0;
    }
    h3 {
      font-size: 30px;
      line-height: 1em;
      font-weight: 700;

      span {
        color: var(--color-1);
      }
    }
    ul {
      margin-top: 40px;

      li {
        padding-left: 30px;
        position: relative;
        color: #292723;
        font-family: var(--font-2);
        line-height: 1.25em;
        font-weight: 500;
        margin-bottom: 30px;

        &:first-of-type {
          margin-top: 30px;
        }
        &::before {
          width: 10px;
          height: 10px;
          border-radius: 3px;
          background-color: var(--color-1);
          content: '';
          left: 0;
          position: absolute;
          top: 5px;
        }
      }
      .separator {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        opacity: 0.45;

        &::before {
          content: '';
          height: 2px;
          width: 100%;
          background-color: #c5c2bc;
          position: absolute;
        }
        &::after {
          content: '';
          background-color: #c5c2bc;
          width: 8px;
          height: 8px;
          border-radius: 2px;
          flex-shrink: 0;
          box-shadow: 0 0 0 10px #fff;
          position: relative;
          transform: rotate(45deg);
        }
      }
    }
  }
  mark {
    font-weight: 700;
    color: var(--color-1);
    background: none;
  }
}

.reviews {
  position: relative;
  max-width: 960px;
  margin: 250px auto 170px;
  padding-right: 20px;
  padding-left: 20px;

  @media screen and (max-width: 500px) {
    margin-top: 150px;
    margin-bottom: 100px;
  }
  h2 {
    font-size: 70px;
    line-height: 0.9em;
    font-weight: 700;
    width: fit-content;
    mask: url(./assets/images/header_mask_4.png) top left / 100% 100% no-repeat;
    margin: 0 auto;
    text-align: center;
    position: relative;

    span {
      color: var(--color-1);
    }
  }
  .description {
    text-align: center;
    font-size: 16px;
    font-family: var(--font-2);
    font-weight: 500;
    line-height: 1.5em;
    margin-top: 20px;
  }
  ul {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 25px;
    margin-top: 90px;

    li {
      min-width: 0;

      img {
        width: 100%;
      }
      a {
        background-color: #fff;
        border-radius: 15px;
        box-sizing: border-box;
        display: block;
        padding: 15px 15px 25px;
        color: inherit;
        text-decoration: none;

        & > div {
          display: flex;
        }
      }
      .button {
        flex: 1;
        display: block;
        border: none;
        padding: 0;
        background: url(./assets/images/16.png) center / 86px auto no-repeat, var(--color-6);
        border-radius: 15px;
        cursor: pointer;
        transition: .15s ease;
      }
      .raiting {
        font-size: 45px;
        font-weight: 700;
        flex-shrink: 0;
        margin-left: 30px;

        span {
          display: block;
          color: #292723;
          opacity: 0.45;
          font-size: 14px;
          font-family: var(--font-2);
          font-weight: 500;
          line-height: 1.5em;
        }
      }
    }

    @media screen and (max-width: 700px) {
      grid-template-columns: 1fr;
      max-width: 250px;
      margin: 50px auto 0;
    }
  }
}

.footer {
  h2 {
    font-size: 70px;
    line-height: 0.9em;
    font-weight: 700;
    width: fit-content;
    mask: url(./assets/images/header_mask_5.png) top left / 100% 100% no-repeat;
    margin: 0 auto;
    text-align: center;
    position: relative;

    span {
      color: var(--color-1);
    }
  }
  .description {
    text-align: center;
    font-size: 16px;
    font-family: var(--font-2);
    font-weight: 500;
    line-height: 1.5em;
    margin-top: 20px;
  }
  .contacts_list {
    margin-top: 100px;
    display: flex;
    justify-content: space-between;
    position: relative;
    padding-bottom: 150px;

    .contacts_list_smarphone {
      position: absolute;
      content: '';
      width: 473px;
      height: 500px;
      background: url(./assets/images/17.png) top center / 100% auto no-repeat;
      left: 0;
      right: 0;
      margin: 0 auto;
      top: -30px;
    }
    @media screen and (max-width: 960px) {
      padding-bottom: 30px;
      flex-direction: column;

      .contacts_list_smarphone {
        top: unset;
        bottom: 0;
        left: unset;
        margin: 0;
        right: -50px;
        height: 570px;
      }
    }
    @media screen and (max-width: 700px) {
      margin-top: 50px;

      .contacts_list_smarphone {
        right: -120px;
        width: 400px;
        height: 500px;
      }
    }
    @media screen and (max-width: 500px) {
      align-items: center;
      text-align: center;
      .contacts_list_smarphone {
        display: none;
      }
      section {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }
  .label {
    font-size: 18px;
    font-weight: 500;
    font-family: var(--font-2);
    color: #1a1a1a;
    opacity: 0.45;
    line-height: 1.5em;
    width: 170px;
    display: block;

    @media screen and (max-width: 700px) {
      font-size: 14px;
    }
  }
  .social_media {
    display: flex;
    margin-top: 25px;
    margin-bottom: 70px;

    a {
      display: block;
      width: 55px;
      height: 55px;
      background: linear-gradient(to bottom, var(--color-3), var(--color-1));
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      &::before {
        content: '';
        background-size: contain;
        background-position: center center;
        width: 28px;
        height: 28px;

      }
      &.vk::before {
        background-image: url(./assets/images/vk_white.png);
      }
      &.instagram::before {
        background-image: url(./assets/images/instagram_white.png);
      }
      &.whatsapp::before {
        background-image: url(./assets/images/whatsapp_white.png);
      }
    }
    li:not(:last-child) {
      margin-right: 15px;
    }
    @media screen and (max-width: 700px) {
      margin-bottom: 50px;
      margin-top: 10px;

      a {
        width: 40px;
        height: 40px;

        &::before {
          height: 16px;
          width: 16px;
        }
      }
      li:not(:last-child) {
        margin-right: 5px;
      }
    }
  }
  .phone {
    font-size: 30px;
    font-family: var(--font-2);
    font-weight: 500;
    line-height: 50px;
    margin-top: 25px;
    display: block;
    color: #000;
    text-decoration: none;

    @media screen and (max-width: 960px) {
      margin-top: 25px;
      line-height: 1em;
    }
    @media screen and (max-width: 700px) {
      font-size: 16px;
      margin-top: 10px;
    }
  }
  .address {
    color: #000;
    font-family: var(--font-2);
    font-size: 25px;
    line-height: 1.2em;
    width: 250px;
    margin-top: 10px;
    height: 2.5em;

    @media screen and (max-width: 960px) {
      margin-bottom: 70px;
      height: auto;
    }
    @media screen and (max-width: 700px) {
      font-size: 16px;
      margin-top: 10px;
      width: 200px;
      margin-bottom: 50px;
    }
  }
  .btn {
    margin-top: 60px;
    width: 240px;
    min-width: unset;

    .front {
      color: #000;
      font-size: 12px;
    }
  }
  .column:nth-child(2) {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
}

@media screen and (max-width: 500px) {
  h2 {
    font-size: 40px!important;
  }
}
</style>
